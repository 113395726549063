<template>
  <div>
    <div
      v-if="packageData"
      @click="handlerAction"
      class="section-container-white container"
    >
      <!-- service info card extension -->
      <div v-if="showFullCard" class="card-content margin-bottom">
        <div class="card-section-one">
          <div>
            <!-- title -->
            <div
              class="title"
              :class="responsiveClass('heading', 'heading-small')"
              v-text="$translations.promos['per-session']"
            />
            <!-- service -->
            <div class="subtitle" v-text="serviceName" />
          </div>

          <!-- coins component -->
          <div>
            <UsagesCoins
              :option="packageData"
              :max="1"
              :remaining="packageData.max_uses"
              :icon-name="iconName"
            />
          </div>
        </div>
        <div class="card-section-two">
          <!-- percentage of discount -->
          <Chip
            class="card-chip"
            :size="'small'"
            :color="'purple'"
            :text="chipText(packageData.discount)"
          />

          <div>
            <!-- price without discount -->
            <div class="body-small price-full" v-text="priceData.perSession" />
            <!-- price with discount -->
            <div
              class="price-discount width-full content-title"
              v-text="priceData.withDiscount"
            />
          </div>
        </div>
      </div>

      <!-- compressed service info card -->
      <div class="card-content">
        <div class="card-section-one">
          <div>
            <!-- title -->
            <div
              class="title"
              :class="responsiveClass('heading', 'heading-small')"
              v-text="packageData.name"
            />
            <!-- service -->
            <div class="subtitle">
              {{ $translations.promos['connector'] }} {{ serviceName }}
            </div>
          </div>

          <!-- coins component -->
          <div>
            <UsagesCoins
              :option="packageData"
              :max="packageData.product_packages[0].max_uses"
              :remaining="remainingCoins"
              :icon-name="iconName"
            />
          </div>
        </div>
        <div v-if="actionType === 'BUY'" class="card-section-two">
          <!-- percentage of discount -->
          <Chip
            class="card-chip"
            :size="'small'"
            :color="'purple'"
            :text="chipText(packageData.discount)"
          />
          <div>
            <!-- price without discount -->
            <div class="body-small price-full" v-text="priceData.saving" />
            <!-- price with discount -->
            <div
              class="price-discount width-full content-title"
              v-text="priceData.total"
            />
          </div>
        </div>
        <div class="card-section-two-alt" v-else>
          <div
            class="body-small text-gray-50"
            v-text="$translations.promos.packages['session-left']"
          />
          <div
            class="content-title text-purple"
            :class="{ 'text-red': remaining === 0 }"
            v-text="remainingSessions"
          />
        </div>
      </div>

      <!-- Addi banner -->
      <div v-if="showAddiBanner && allowedInCountry('CO')" class="addi-banner">
        <img :src="require('@/assets/icons/addi-logo.png')" alt="" />
        <div v-html="addiText" />
      </div>

      <!-- showSpecialists button -->
      <Button
        class="content-title text-purple padding-bottom-xs padding-x-zero"
        v-if="showSpecialists"
        :text="getTextButtonPackage"
        type="link"
        icon="icon-arrow-small-outline-right"
        @clicked="goToSpecialistsList"
      />

      <!-- button -->
      <Button
        class="margin-top"
        v-if="showButton"
        :text="buttonText"
        :type="buttonType"
        @clicked="navigateToCheckout"
      />
    </div>

    <!-- modals -->
    <PackageModal
      v-if="showDetails"
      :title="packageData.name"
      :package-data="packageData"
      :service-name="serviceName"
      :discount="packageData.product_packages[0].price.discount || packageData?.discount"
      :specialist-id="specialistId"
      :icon-name="iconName"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import PackageModal from './PackageModal'
import UsagesCoins from '@/components/UsagesCoins'
import { PackageEvent, AddiEvent } from '@/types/events'
import {
  Chip,
  Button,
  ResponsiveMixin,
  CurrencyFilter
} from '@seliaco/red-panda'
import countryValidation from '@/mixins/country-validation'
import { PaymentMethods } from '@seliaco/red-panda/src/services'
import { mapGetters } from 'vuex'

export default {
  name: 'PackageCard',
  mixins: [ResponsiveMixin, countryValidation],
  components: {
    PackageModal,
    UsagesCoins,
    Chip,
    Button
  },
  props: {
    packageData: Object,
    serviceName: String,
    specialistId: String,
    isProgram: Boolean,
    showButton: {
      type: Boolean,
      default: true
    },
    showSpecialists: Boolean,
    showFullCard: Boolean,
    canOpenDetail: Object,
    currency: String,
    iconName: String,
    /* indicate if the card is show to buy a package or to consult user packages */
    actionType: {
      type: String,
      default: 'BUY'
    },
    buttonText: String,
    buttonType: {
      type: String,
      default: 'block'
    },
    showAddi: {
      type: Boolean,
      default: false
    },
    remaining: Number,
    isClickable: Boolean
  },
  data () {
    return {
      showDetails: false,
      showAddiBanner: false,
      addiText: null,
      specialists: null
    }
  },
  mounted () {
    if (this.showAddi) {
      const shortContent = true
      PaymentMethods.getLoanBanner(
        'PACKAGE',
        this.packageData.id,
        shortContent
      ).then((response) => {
        this.showAddiBanner = response.showBanner
        this.addiText = response.content
      })
    }
  },
  methods: {
    handlerAction () {
      if (!this.isClickable) {
        return
      }
      PackageEvent.packageCardClick({
        user: this.$store.getters['auth/user'],
        detailsPackage: {
          title: this.serviceName,
          ...this.packageData,
          ...this.packageData.service_price
        },
        source: this.$route.name
      })

      if (this.canOpenDetail?.modal) {
        this.showDetails = true
      } else if (this.canOpenDetail?.view) {
        this.$router.push({
          name: 'GenericPackageDetail',
          query: {
            packageData: JSON.stringify(this.packageData),
            serviceName: this.serviceName,
            back: this.$route.fullPath
          }
        })
      } else if (this.showButton) {
        this.$emit('click-button')
      }
    },
    closeModal () {
      this.showDetails = false
    },
    chipText (value) {
      if (value > 0) {
        return `${value}% ${this.$translations.promos.discount}`
      } else {
        return this.$translations.general['free-session']
      }
    },
    goToSpecialistsList () {
      if (this.isProgram) {
        this.$router.push({
          name: 'SpecialistsList',
          query: {
            title: this.$translations.programs.detail.specialists.available,
            back: this.$route.fullPath,
            specialistSource: JSON.stringify({
              id: this.packageData.id,
              store: 'programs',
              action: 'getProgramSpecialists',
              getter: 'getProgramSpecialists'
            })
          }
        })
        return
      }

      this.$router.push({
        name: 'PackageSpecialists',
        params: {
          packageId: this.packageData.id
        },
        query: {
          back: this.$route.fullPath
        }
      })
    },
    navigateToCheckout () {
      const params =
        this.showAddiBanner && this.allowedInCountry('CO') ? 'ADDI' : null
      this.$segment.track(AddiEvent.select_addibanner)
      let query = {
        back: this.$route.fullPath,
        specialistId: this.specialistId
      }

      if (params) {
        query = {
          ...query,
          paymentMethodType: params
        }
      }

      this.$router.push({
        name: 'CheckOut',
        params: {
          id: this.packageData.id,
          type: 'PACKAGE'
        },
        query
      })

      PackageEvent.selectPackage({
        user: this.$store.getters['auth/user'],
        detailsPackage: {
          title: this.serviceName,
          ...this.packageData,
          ...this.packageData.service_price
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      filterOptions: 'filtersV2/options'
    }),
    priceData () {
      const result = (value) => CurrencyFilter(value / 100, this.currencyCode)
      const product = this.packageData.product_packages[0]

      if (this.showFullCard) {
        return {
          saving: result(product.price.total),
          total: result(this.packageData.price),
          perSession: result(product.price.unit),
          withDiscount: result(product.price.with_discount)
        }
      }

      return {
        saving: result(product.price.total),
        total: result(this.packageData.price)
      }
    },
    currencyCode () {
      return this.currency.toUpperCase()
    },
    remainingSessions () {
      return this.$translations.promos.packages['remaining-session'].replaceAll(
        '{remaining}',
        this.remaining
      )
    },
    remainingCoins () {
      return this.remaining
        ? Number(this.remaining)
        : Number(this.packageData.product_packages[0].max_uses)
    },
    getTextButtonPackage () {
      let translations = this.$translations.promos.packages['card-button']

      if (this.isProgram) {
        translations = this.$translations.promos.packages['card-button-program']
      }

      return translations
    }
  },
  filters: {
    CurrencyFilter
  }
}
</script>

<style lang="sass" scoped>
::v-deep .modal-backdrop
  z-index: 999

::v-deep .modal-container
  z-index: 1000

.container
  display: flex
  flex-direction: column
  justify-content: space-between
  .card-content
    width: 100%
    display: grid
    justify-content: space-between
    grid-template-columns: repeat(2, max-content)
    .card-section-one
      display: grid
      grid-template-rows: 1fr min-content
      gap: 10px
      .title
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        margin-bottom: 4px
      .subtitle
        font-size: var(--tiny)
        color: var(--gray-60)
        white-space: nowrap
    .card-section-two
      display: grid
      grid-template-rows: min-content 1fr
      gap: 10px
      align-self: end
      .price-full
        text-align: right
        color: var(--gray-50)
        text-decoration: line-through
        white-space: nowrap
        margin-bottom: 4px
      .price-discount
        text-align: right
        align-self: center
        white-space: nowrap
      .card-chip
        margin-right: 0
        margin-left: auto
    .card-section-two-alt
      display: grid
      text-align: right
      align-self: end
.addi-banner
  margin-top: 16px
  display: flex
  justify-content: center
  align-items: center
  gap: 8px
  padding: 4px 8px
  width: 100%
  background: var(--blue-10)
  border-radius: 8px
  color: #4E7DFF
  font-weight: 400
  font-size: 12px
  img
    width: 20px
    height: 20px
</style>
