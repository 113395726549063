<template>
  <BottomDialog
    :title="title"
    :no-padding="true"
    :mainAction="{ text: $translations.promos['packages'].buy }"
    @on-nav-back="closeModal"
    @action-clicked="navigateToCheckout"
  >
    <template v-slot:content>
      <!-- Package Card -->
      <PackageCard
        :service-name="serviceName"
        :package-data="packageData"
        :currency="packageData.product_packages[0].price.currency"
        :show-button="false"
        :can-open-modal="false"
        :icon-name="iconName"
      />
      <!-- Package Description -->
      <div
        class="package-modal-description body padding-y margin-top"
        v-text="packagePromotionText"
      />

      <!-- title -->
      <div
        class="heading-small margin-bottom"
        v-text="$translations.promos['packages']['value-with-pack']"
      />

      <!-- Full Package Card -->
      <PackageCard
        class="margin-bottom"
        :service-name="serviceName"
        :package-data="packageData"
        :show-button="false"
        :show-full-card="true"
        :can-open-modal="false"
        :currency="packageData.product_packages[0].price.currency"
        title-size="2"
        :icon-name="iconName"
      />

      <div
        class="package-modal-saving margin-top bg-purple-10 text-purple body"
      >
        <div class="icon-money-off heading text-purple-50" />
        <div class="text-center content-title">
          {{ $translations.promos['packages']['total-saving'] }}
          {{ getCurrency }}
        </div>
      </div>

      <AddiBanner
        v-if="validAddi"
        type="PACKAGE"
        :id="packageData.id"
        @clicked="navigateToCheckout('ADDI')"
      />
    </template>
  </BottomDialog>
</template>

<script>
import {
  BottomDialog,
  ResponsiveMixin,
  CurrencyFilter as currency
} from '@seliaco/red-panda'
import AddiBanner from '@/components/banner/AddiBanner'
import { PackageEvent } from '@/types/events'

export default {
  name: 'PackageModal',
  components: {
    PackageCard: () => import('./PackageCard'),
    BottomDialog,
    AddiBanner
  },
  mixins: [ResponsiveMixin],
  props: {
    title: String,
    serviceName: String,
    discount: Number,
    packageData: {},
    specialistId: String,
    iconName: String
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    navigateToCheckout (additionalData) {
      // this.$segment.track(AddiEvent.select_addibanner)
      let query = {
        specialistId: this.specialistId,
        back: this.$route.fullPath
      }

      if (additionalData) {
        query = {
          ...query,
          paymentMethodType: additionalData
        }
      }

      this.$router.push({
        name: 'CheckOut',
        params: {
          id: this.packageData.id,
          type: 'PACKAGE'
        },
        query
      })
      PackageEvent.selectPackage({
        user: this.$store.getters['auth/user'],
        detailsPackage: {
          title: this.serviceName,
          ...this.packageData,
          ...this.packageData.service_price
        }
      })
    },
    packageDataWithName () {
      const serviceName = this.serviceName
      return { ...this.packageData, serviceName }
    }
  },
  computed: {
    packagePromotionText () {
      if (this.packageData.content) {
        return this.packageData.content
      }

      let text = this.$translations.promos.packages['modal-text']

      text = text.replaceAll('{title}', this.title)
      text = text.replaceAll('{discount}', this.discount)
      text = text.replaceAll('{service_name}', this.serviceName)

      return text
    },
    getCurrency () {
      const currencyCode =
        this.$store.getters['auth/user'].currency.toUpperCase()

      return currency(
        this.packageData.product_packages[0].price.savings / 100,
        currencyCode
      )
    },
    validAddi () {
      return this.$store.getters['auth/user'].country === 'CO'
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal-content {
    padding: 16px;
  }

  .modal-content div,
  .modal-content > div {
    height: unset;
  }
}

.package-modal-description {
  padding: 0 2px;
}
.package-modal-saving {
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 8px;
}
</style>
