<template>
  <TabContainer
    :title="$translations.checkout['my-packages']"
    :actions="actions"
    :route="$route"
    :options="options"
    :hasShyHeader="true"
  >
    <template v-slot:content>
      <!-- skeleton -->
      <template v-if="loading">
        <div class="segment margin-top">
          <div class="segment-content">
            <Skeleton
              v-for="(v, i) in 3"
              :key="i"
              width="100%"
              height="121px"
              borderRadius="16px"
              class="margin-bottom"
            />

            <Skeleton width="100%" height="80px" borderRadius="16px" />
          </div>
        </div>
      </template>

      <!-- packages list -->
      <div
        class="page-segment margin-top"
        v-if="!loading && packages.length > 0"
      >
        <div class="my-package-list page-segment-content">
          <PackageCard
            v-selectable-container="isClicked"
            :service-name="serviceName(packageData)"
            :package-data="packageData.package"
            :show-button="hasButton"
            :show-specialists="true"
            :button-text="$translations.promos.buttons['buy-again']"
            button-type="outline"
            :action-type="isDisabled"
            :icon-name="packageData.package.product_packages[0].icon_name"
            :currency="packageData.package.product_packages[0].price.currency"
            :remaining="packageData.user_product_packages[0].remaining_uses"
            :is-program="packageData.is_program"
            @click-button="goCheckout(packageData.package.id)"
            :key="i"
            :is-clickable="isClickeable"
            v-for="(packageData, i) in packages"
          />
        </div>
      </div>

      <!-- data zero -->
      <template v-if="!loading && packages.length === 0">
        <div class="segment margin-top">
          <div class="segment-content my-packages-datazero">
            <img
              src="@/assets/discount-datazero.png"
              class="image"
              alt="%"
              width="120px"
              height="120px"
            />
            <div class="content-title" v-text="getTitleDataZero" />
          </div>
        </div>
      </template>

      <div class="segment" v-if="!loadingPromotion">
        <div class="segment-content">
          <!-- promotion banner -->
          <PackagePromotionBanner
            :discount-percent="discountPercent"
            background-color="white"
            origin="my-specialists"
            :specialist="specialist"
            :extended="isExtended"
            :specialists="specialists"
          />
        </div>
      </div>
    </template>
  </TabContainer>
</template>

<script>
import PackageCard from './components/PackageCard'
import {
  TabContainer,
  Skeleton,
  selectableContainer,
  Promos
} from '@seliaco/red-panda'
import PackagePromotionBanner from '@/views/promos/packages/components/PackagePromotionBanner'
import { mapGetters } from 'vuex'

export default {
  name: 'MyPackages',
  components: { TabContainer, PackagePromotionBanner, Skeleton, PackageCard },
  directives: { selectableContainer },
  data () {
    const options = [
      {
        text: this.$translations.promos.tab.actives,
        name: 'actives',
        action: () => {
          this.$router.replace({
            query: {
              tab: 'actives'
            }
          })
        }
      },
      {
        text: this.$translations.promos.tab.completed,
        name: 'completed',
        action: () => {
          this.$router.replace({
            query: {
              tab: 'completed'
            }
          })
        }
      }
    ]

    return {
      discountPercent: 12,
      options,
      actions: {
        back: {
          callback: () => {
            this.$router.push({ name: 'Home' })
          }
        }
      },
      loadingPromotion: true,
      specialist: null,
      specialists: null
    }
  },
  created () {
    if (this.nextAppointment.specialist) {
      this.setSpecialists()
    } else {
      this.$store.dispatch('setNextAppointment')

      this.$store.watch(
        () => this.nextAppointment,
        () => {
          this.setSpecialists()
        }
      )
    }

    this.getMyPackages()
  },
  methods: {
    setSpecialists () {
      const { specialist } = this.nextAppointment
      this.specialist = specialist
      Promos.Packages.getAllUserSpecialistsWithActivePackages()
        .then((response) => {
          this.specialists = response.specialists
        })
        .catch((error) => console.error('Error: ', error))
      this.loadingPromotion = false
    },
    getMyPackages () {
      const endpoint =
        this.$route.query.tab === 'completed'
          ? 'getUserPackageServiceDepleted'
          : 'getUserPackageService'

      this.$store.dispatch('myPackages/get', { endpoint: endpoint })
    },
    goCheckout (id) {
      this.$router.push({
        name: 'CheckOut',
        params: {
          id,
          type: 'PACKAGE'
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      nextAppointment: 'nextAppointment',
      packages: 'myPackages/getPackages',
      loading: 'myPackages/getLoading',
      program: 'programs/getProgram',
      language: 'language'
    }),
    isExtended () {
      const { specialist } = this.nextAppointment
      return !specialist
    },
    getTitleDataZero () {
      const translations = this.$translations.promos.datazero
      return this.$route.query.tab === 'actives'
        ? translations.actives
        : translations.completed
    },
    hasButton () {
      return this.$route.query.tab === 'completed'
    },
    isDisabled () {
      return this.$route.query.tab === 'completed' ? 'BUY' : 'PACKAGE_CONSULT'
    },
    isClicked () {
      return this.$route.query.tab === 'completed' ? { elementLevel: 1 } : null
    },
    isClickeable () {
      return this.$route.query.tab === 'completed'
    },
    serviceName () {
      return (packageData) => {
        return packageData.package.product_packages[0][
          `name_${this.language.lang}`
        ]
      }
    }
  },
  watch: {
    '$route.query': {
      handler () {
        this.getMyPackages()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.my-packages-datazero
  display: flex
  flex-direction: column
  gap: 16px
  justify-content: center
  margin-top: 32px
  align-items: center

.my-package-list
  display: flex
  flex-direction: column
  gap: 16px
</style>
