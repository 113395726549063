<template>
  <div class="packages-banner-container" :class="containerStyle">
    <!-- left column -->
    <div class="banner-text">
      <!-- icon -->
      <div class="icon">
        <img
          src="@/assets/discount-alt.png"
          class="image"
          :class="{ 'image-big': extended }"
          alt="%"
        />
        <!--
        to deprecate
        <img v-else src="@/assets/discount.png" class="image" alt="%">
-->
      </div>

      <!-- extended text -->
      <div v-if="extended" class="extended-text">
        <div class="content-title">
          {{ $translations.promos.packages.saving }}
        </div>
        <div class="body-small" v-html="promotionTextAlt"></div>
      </div>

      <!-- text -->
      <div v-else :style="textStyle" class="text" v-html="promotionText"></div>
    </div>

    <!-- right column -->
    <div class="banner-button">
      <Button
        :type="'outline'"
        :text="$translations.promos.packages['view-all']"
        @clicked="goToPackageView"
      />
      <!--
       to deprecate
       <Button
        v-else
        :color="'white'"
        :text="$translations.promos.packages['view-all']"
        @clicked="goToPackageView" />-->
    </div>
  </div>
</template>

<script>
import { PackageEvent } from '@/types/events'
import { Button } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'PackagePromotionBanner',
  components: {
    Button
  },
  props: {
    discountPercent: Number,
    origin: String,
    backgroundColor: {
      type: String,
      default: 'purple'
    },
    icon: String,
    specialist: Object,
    extended: {
      type: Boolean,
      default: false
    },
    specialists: Array
  },
  methods: {
    goToPackageView () {
      PackageEvent.packagesClick({
        source: this.$route.name,
        user: this.user,
        type:
          !this.specialist?.id || this.extended
            ? 'Generic Packages'
            : 'Specialist Packages'
      })

      if (
        this.origin !== 'specialist-availability' &&
        (!this.specialist?.id ||
          this.extended ||
          this.user.status === 'ACCOUNT_CREATED')
      ) {
        this.$router.push({
          name: 'ServicePackages',
          query: {
            back: this.$route.fullPath
          }
        })
      } else {
        let specialists = JSON.stringify(this.specialists)

        if (this.specialist) {
          let name = `${this.specialist.first_name} ${this.specialist.last_name}`
          let picture = this.specialist.profile_picture

          if (this.specialist.user) {
            const user = this.specialist.user
            name = `${user.first_name} ${user.last_name}`
            picture = user.profile_picture
          }

          specialists = JSON.stringify([
            {
              id: this.specialist.id,
              name,
              picture
            }
          ])
        }

        this.$router.push({
          name: 'Packages',
          query: {
            specialists,
            back: this.$route.fullPath,
            origin: this.origin
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    promotionText () {
      const text = this.$translations.promos.packages.message

      return text.replaceAll('{discount}', `${this.discountPercent}%`)
    },
    promotionTextAlt () {
      const text = this.$translations.promos.packages['saving-description']

      return text.replaceAll('{discount}', `${this.discountPercent}%`)
    },
    textStyle () {
      if (this.backgroundColor === 'white') {
        return {
          '--text-color-banner': 'var(--gray-80)',
          '--text-size-banner': 'var(--sm)'
        }
      }

      return {
        '--text-color-banner': 'var(--white)',
        '--text-size-banner': 'var(--tiny)'
      }
    },
    containerStyle () {
      let containerClass = ''

      if (this.extended) {
        containerClass = containerClass.concat(' ', 'banner-extended')
      }

      switch (this.backgroundColor) {
        case 'purple':
          return containerClass.concat(' ', 'banner-color-purple')
        case 'white':
          return containerClass.concat(' ', 'banner-color-white')
        default:
          return null
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.packages-banner-container
  display: grid
  grid-template-columns: 1fr min-content
  border-radius: 16px
  .banner-text
    display: grid
    grid-template-columns: min-content 1fr
    align-content: center
    gap: 8px
    .icon
      align-self: center
    .image
      min-width: 40px
    .image-big
      min-width: 56px
    .text
      color: var(--text-color-banner)
      font-size: var(--text-size-banner)
      align-self: center
    .extended-text
      display: grid
      gap: 4px
  .banner-button
    align-self: center
    white-space: nowrap
  .extended
    grid-template-columns: 1fr

.banner-color
  &-purple
    background-color: var(--purple)
    padding: 16px
    gap: 8px
  &-white
    background-color: var(--white)
    padding: 16px
    border: 2px solid var(--gray-10)
    box-shadow: var(--shadow-gray)
    gap: 8px

.banner-extended
  grid-template-columns: 1fr
</style>
