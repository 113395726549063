<template>
  <div>
    <template v-if="version === 1">
      <div
        class="addi-banner"
        v-if="banner.showBanner"
        @click="$emit('clicked')"
      >
        <img class="addi-banner_img" :src="img" alt="Addi-logo" />
        <div class="addi-banner_body body-small">
          <span v-html="banner.content" />
          <img class="img" :src="imgText" alt="Addi-logo-letras" />.
          <span class="heading-title" v-text="$translations.promos.addi" />
        </div>
      </div>
    </template>
    <template v-if="version === 2">
      <div
        class="addi-banner-v2"
        v-if="banner.showBanner"
        @click="$emit('clicked')"
      >
        <div class="addi-banner-v2_body body-small">
          <span v-html="banner.content" />
          Addi
        </div>
        <img
          class="addi-banner-v2_img"
          src="https://igpedxmysqtwteudvnol.supabase.co/storage/v1/object/public/banner-assets/banner-addi.png"
          alt="Addi-logo"
        />
      </div>
    </template>
  </div>
</template>
<script>
import { PaymentMethods, PNG } from '@seliaco/red-panda'

export default {
  name: 'AddiBanner',
  props: {
    type: String,
    id: String,
    version: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      img: PNG.ADDI,
      imgText: PNG.ADDIALT,
      banner: {
        showBanner: null,
        content: null
      }
    }
  },
  mounted () {
    PaymentMethods.getLoanBanner(this.type, this.id).then((response) => {
      this.banner = {
        ...response,
        content: response.content
      }
    })
  }
}
</script>
<style lang="sass" scoped>
$addiColor: #4E7DFF
$addiColor2: #0A4670

.addi-banner
  margin-top: 16px
  border: 1px solid #C3C8D5
  display: flex
  align-items: center
  border-radius: 8px
  overflow: hidden
  &_img
    height: 50px
    padding: 10px 6px
    background: $addiColor
    border-radius: 6px 0 0 6px
  &_body
    padding: 8px
    background: white
    border-radius: 0 8px 8px 0
    .img
      margin-left: 4px
      display: inline-block
      vertical-align: baseline
      width: 46px
      margin-left: 4px
      cursor: pointer
    .heading-title
      color: $addiColor

.addi-banner-v2
  height: 72px
  background: var(--blue-10)
  border-radius: 16px
  display: flex
  overflow: hidden
  justify-content: space-between
  align-items: center
  padding: 16px
  position: relative
  &_img
    height: 110px
    padding: 10px 6px
    border-radius: 6px 0 0 6px
    position: absolute
    right: -13px
    bottom: -33px
  &_body
    padding: 8px
    color: $addiColor
    .heading-title
      color: $addiColor

.addi-banner-v2_body span
  color: $addiColor
.addi-banner-v2_body > span > b
  color: $addiColor
</style>
