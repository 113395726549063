<template>
  <div v-if="option" :style="coinStyles">
    <template v-if="max <= 9">
      <span
        v-for="(usage, index) in max"
        :key="index"
        class="icon icon-appointment-on"
        :class="coinsClass(index)"
        :style="{ 'z-index': max - index }"
      >
      </span>
    </template>

    <template v-else-if="max > 9">
      <span class="icon icon-appointment-on background-color-enabled"> </span>

      <span class="small-icon-option-text">
        {{ remaining }}<span class="max-uses"> / {{ max }}</span>
      </span>
    </template>
  </div>
</template>

<script>
import { ServiceIcons } from '@seliaco/red-panda'

export default {
  name: 'UsagesCoins',
  props: {
    option: Object,
    max: Number,
    remaining: Number,
    color: String,
    iconName: String
  },
  computed: {
    coinStyles () {
      const backgroundColor = ServiceIcons[this.iconName].background
      const backgroundDisableColor = ServiceIcons[this.iconName].disable
      const textColor = ServiceIcons[this.iconName].color
      return {
        '--background-color': backgroundColor,
        '--background-disable-color': backgroundDisableColor,
        '--coin-text-color': textColor
      }
    },
    coinsClass () {
      return (index) => {
        if (index + 1 <= this.remaining) {
          return 'background-color-enabled'
        }
        return 'background-color-disabled'
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.icon
  position: relative
  border-radius: 9999px
  color: var(--coin-text-color)
  border: 1px solid var(--color-white)
  padding: 3.33px
  font-size: 13.33px
  margin-left: -10.5px

  &:first-child
    margin-left: 0px

  &.background-color-enabled
    background-color: var(--background-color)
  &.background-color-disabled
    background-color: var(--background-disable-color)

.big-icon-option-text
  margin-left: 6px
  font-weight: 600
  font-size: 14px
  color: var(--color-dark-gray)
.small-icon-option-text
  font-weight: 600
  font-size: 14px
  color: var(--color-dark-gray)
  .max-uses
    color: var(--coin-text-color)
</style>
